import React, {useState, useEffect, useRef} from 'react';
import SplitType from 'split-type';
import classNames from 'classnames';

export const TextAnimationHeading = (props) => {
  const el = useRef(null);

  const _classNames = classNames({
    'text-animation-heading': true,
    [`as-${props.textStyle}`]: true
  });

  const initialDelay = props.delay || 0;
  const delay = props.faster ? 20 : 25;

  useEffect(() => {
    const text = new SplitType(el.current, { types: 'words, chars' });
    text.chars.forEach((letter, index) => letter.style.animationDelay = `${initialDelay + (delay * index)}ms`);
  },[]);

  return(
    <span className={_classNames} ref={el}>{ props.text }</span>
  )
}
