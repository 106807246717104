import React from 'react';

import './index.scss';

export default class Layout extends React.Component {
  render = () => (
    <>
      { this.props.children }
    </>
  )
}