import React from 'react';
import classNames from 'classnames';

export class HtmlText extends React.Component {
  state = {
    key: 'value'
  }

  get className () {
    return classNames({
      'html-text': true
    });
  }

  render = () => <div className={this.className} dangerouslySetInnerHTML={{ __html: this.props.html }} />;
}
