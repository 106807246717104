import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

export const MobileOverlay = (props) => {
  const [openIndex, setOpenIndex] = useState(-1);

  return (
    <nav className="overlay-mobile">
      <ul className="overlay-mobile__primary">
        { props.items.map((item, index) => (
          <MobileNavItem
            key={index}
            item={item}
            index={index}
            open={index === openIndex}
            onOpen={index => setOpenIndex(index)}
          />
        )) }
      </ul>
      <ul className="overlay-mobile__secondary">
        { props.secondary.map((item, index) => (
          <MobileNavSecondary item={item} index={index} />
        )) }
      </ul>
    </nav>
  )
}

const MobileNavItem = (props) => {
  const hasSubs = (props.item.subnavigation?.length || -1) > 0

  const className = classNames({
    'mobile-nav-item': true,
    'mobile-nav-item--open': props.open,
    'mobile-nav-item--has-subs': hasSubs
  });

  const clickHander = (e) => {
    const event = new Event('nav-click');
    document.dispatchEvent(event);
  }

  return (
    <li className={className}>
      <Link to={props.item.linksToPage?.slug} onClick={clickHander}>{ props.item.title }</Link>
      <div className="mobile-nav-item__caret" onClick={() => props.onOpen(props.index) }>
        <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 1.25586L10 10.2559L1 1.25586" stroke="white"/>
        </svg>
      </div>
      { hasSubs && (
        <div className="mobile-nav-item__sub">
          { props.item.subnavigation.map((item, index) => (
            <Link key={index} to={item.linksToPage?.slug} onClick={clickHander}>{item.title}</Link>
          ))}
        </div>
      ) }
    </li>
  );
}

const MobileNavSecondary = (props) => {
  const className = classNames({
    'mobile-nav-secondary': true
  });

  return (
    <li className={className}>
      <Link to={props.item.linksToPage?.slug}>{ props.item.title }</Link>
    </li>
  );
}
