import React from 'react';
import { Link } from 'gatsby';

export const mediaENUM = {
  S: { maxWidth: 720 },
  M: { minWidth: 720 },
  L: { minWidth: 1024 },
  X: { minWidth: 1440 },
  XL: { minWidth: 1920 },
  disabled: { maxWidth: 0 }
};

export const makeLink = (link) => {
  return link.linksToPage
    ? <Link to={link.linksToPage?.slug || '#'}>{link.title}</Link>
    : <a href={link.externalLink || '#'}>{link.title}</a>;
}