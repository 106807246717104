import React from 'react';
import { Link } from 'docz';
import classNames from 'classnames';

export const Button = (props) => {

  const _classNames = classNames({
    'button': true,
    'button--primary': props.primary,
    'button--primary--dark': props.primaryDark,
    'button--secondary': props.secondary,
    'button--secondary--dark': props.secondaryDark,
    'button--disabled': props.disabled,
    'button--news': props.news,
    'button--secondary--outline': props.secondaryOutline,
    'button--secondary--outline-dark': props.secondaryOutlineDark,
  });

  return (
    <>
      { 
        props.type==="submit"
        ? <button to={props.internal} className={_classNames} data-text={props.text} form={props.form} disabled={props.disabled} onClick={props.onClick}>{props.text} </button>
        : props.external
        ? <a href={props.external} className={_classNames} data-text={props.text} target="_blank">{props.text}</a>
        : <Link to={props.internal} className={_classNames} data-text={props.text} >{props.text}</Link>
      }
    </>
  )
}
