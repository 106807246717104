import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { SignUpForm } from '../../components/sign-up-form';

import { makeLink } from '../../helpers/helpers';
import { HtmlText } from '../../components/html-text';


export const Footer = (props) => {
  const _classNames = classNames({
    'footer': true
  });

  return (
    <footer className={_classNames}>
      <div className="footer_wrapper">
        <div className="footer-top">
          <div className="subscribtions">
            
            <SignUpForm btnText={props.newsletterButton} subscribeMessage={props.newsletterMessage} title={props.newsletterTitle} body={props.newsletterBody} dark={true} />
          </div>
          <div className="quick-links">
            <div className="quick-links_column">
              {props.linksColumn1.map(link => makeLink(link))}
            </div>
            <div className="quick-links_column">
              {props.linksColumn2.map(link => makeLink(link))}
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-bottom_links">
            <div className="terms-links">
              {props.legalLinks.map(link => makeLink(link))}
            </div>
            <div className="social-links">
              {props.socialLinks.map(link => makeLink(link))}
            </div>
          </div>
          <p className="copyright">©{moment().format('YYYY')} Nacero Inc. All rights reserved</p>
        </div>
      </div>
    </footer>
  )
}
