import React, { useState } from 'react';
import classNames from 'classnames';
import { Button } from '../button/index'
import { HtmlText } from '../html-text';
import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';

export const SignUpForm = (props) => {

  const _classNames = classNames({
    'subscribtion-form': true,
    'subscribtion-form--dark': props.dark,
  });

  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [formMessage, setFormMessage] = useState();

  const validateEmail = (email) => {
    if(email.match(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)){
      return true;
    }else {
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) setIsValid(false);

    if (isValid && email) {
      //Submit form
      var uri = [ 'https://nacero.us5.list-manage.com/subscribe/post-json?u=66f3547305853ecab73aebc89&id=b18ba9c1da', '&EMAIL=', email ];

      
      axios.get(uri.join(''), { adapter: jsonpAdapter, callbackParamName: 'c' }).then(response => {
        if(response.data.result === "success") {
          setFormMessage(props.subscribeMessage);
        } else {
          setFormMessage(response.data.msg);
        };
      });
    }
  }

  return (
    <form className={_classNames}>
      {props.title && <h3 className="subscribtion-form__title">{formMessage ? <HtmlText html={formMessage}/> : props.title}</h3>}
      { (props.body && !formMessage) && <div className="subscribtion-text">
        <HtmlText html={props.body} />
      </div>}
      {!formMessage && <div className="subscription-form__input">
        <input className="email-field" type="email" placeholder="name@email.com"
          onChange={(e) => {if(validateEmail(e.target.value)) {
            setIsValid(true);
            setEmail(e.target.value)
          }else{setIsValid(false)}
        }}
        />
        <Button type="submit" primary primaryDark={props.dark}
          disabled={!isValid && !validateEmail(email)}
          text={props.btnText || 'subscribe'}
          onClick={handleSubmit}
        />
        {!isValid &&
          <p className="email-field__error-message">please enter valid email address</p>
        }
        <p className="subscribtion-form__legal-text">
          By signing up, you agree to our <a href="/legal/privacy-policy" target="_blank">Privacy Policy</a> and <a href="/legal/terms-of-use" target="_blank">Terms of Use</a>, including receipt of emails, news, and updates. You can unsubscribe at any time.
        </p>
      </div>}
    </form>
  )
}